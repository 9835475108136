<template>
    <div class="consumption">
        <div class="padd20 borbut20 consumptionTop">
            <div class="geshi">
                导入文件标准格式：<a href="https://elc-devtest.oss-cn-beijing.aliyuncs.com/test/aeolus_20211127_122447_stGAOaMJgT.xlsx" target="_blank" rel="noopener noreferrer">下载格式</a>
            </div>
            <el-upload class="upload-demo" drag :action="$api.upload + '?token=' + '7ce563cc4f49544991de6ed0ac743bbe'" :limit="2" ref="upload" list-type="picture" show-file-list :file-list="fileListUpload" :on-remove="onRemoveupload" :on-success="onSuccessupload" :on-error='onError' :before-upload="onChangeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip text" slot="tip">最多可传 1 个 <el-button type="primary" @click="onSubmit()" style="margin-left:20px" :disabled='disabled == 0 ? false : true'>提交识别</el-button></div>
                <!-- <div class="el-upload__tip text" slot="tip">最多可传 1 个 <el-button type="primary" @click="onSubmit()" :icon="disabled == 0 ? '' : 'el-icon-loading'" style="margin-left:20px" :disabled='disabled == 0 ? false : true'>{{disabled == 0 ? '识别下载' : disabled == 1 ? '正在上传' : '正在识别'}}</el-button></div> -->
            </el-upload>
        </div>
        <div class="padd20 consumptionList">
            <el-table ref="filterTable" :data="consumptionList" style="width: 100%">
                <el-table-column prop="id" label="文件ID"></el-table-column>
                <el-table-column prop="created_at" label="上传时间"></el-table-column>
                <el-table-column prop="id" label="文件状态">
                     <template slot-scope="scope">
                         <el-tag :type="scope.row.status == 1 ? 'info' : scope.row.status == 2 ? '' : scope.row.status == 3 ? 'success' : scope.row.status == 4 ? 'danger' : '--'">{{scope.row.status == 1 ? "等待执行" : scope.row.status == 2 ? "正在执行" : scope.row.status == 3 ? "已完成" : scope.row.status == 4 ? "失败" : '--'}}</el-tag>
                         <!-- {{scope.row.status == 1 ? "等待执行" : scope.row.status == 2 ? "正在执行" : scope.row.status == 3 ? "已完成" : scope.row.status == 4 ? "失败" : '--'}} -->
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="文件下载">
                     <template slot-scope="scope">
                         <el-link :href="scope.row.result_file_url" target="_blank" type="primary" v-if="scope.row.status == 3">点击下载</el-link>
                         <span v-else>--</span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem('TZ-USER')).admin_token,
            fileListUpload: [],
            file_id: "",
            disabled:0,
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据

            consumptionList:[],//识别列表
            Timeout:null,//循环定时器
            mediaKey:0,//循环的key
        };
    },
    mounted() {
        this.onExcegetList()
    },

    methods: {
        onExcegetList(){
            // 获取列表
            var param = {
                
            }
            this.$service.get(this.$api.ExcegetList, param, (res) => {
                if (res.code == "200") {
                    this.consumptionList = res.data.data
                    this.lvtotal = res.data.count;
                    this.onmediaKey(res.data.data)
                }
            });
        },
        // 执行获取结果
        onmediaKey(data) {
            if (data.length) {
                if (data.length == this.mediaKey) {
                    return;
                } else {
                    if (data[this.mediaKey].status == 1 || data[this.mediaKey].status == 2) {
                        return this.onGain(data[this.mediaKey].id);
                    } else {
                        this.mediaKey++;
                        this.onmediaKey(data);
                    }
                }
            }
        },
        //获取结果
        onGain(id) {
            var param = {
                deleteJiaZai: true,
                id: id,
            };
            this.$service.get(this.$api.searchById, param, (res) => {
                if (res.code == "200") {
                    if (res.data.status == 3 || res.data.status == 4) {
                        this.$message({message: 'ID' + res.data.id + '识别成功',type: "success",});
                        clearInterval(this.Timeout);
                        this.onExcegetList();
                    } else {
                        this.Timeout = setTimeout(() => {
                            this.onGain(id);
                        }, 3000);
                    }
                    // console.log("获取结果", res);
                }
            });
        },
        // 提交识别
        onSubmit() {
            var param = {
                file_id: this.file_id,
            };
            if (param.file_id == "") {
                this.$message({ message: "请上传文件", type: "warning" });
                return;
            }
            this.disabled = 2 
            this.$service.post(this.$api.postExcelCost, param, (res) => {
                this.disabled = 0
                if (res.code == "200") {
                    this.$message({message: "文件提交成功，识别成功后列表下载",type: "success",});
                    clearInterval(this.Timeout);
                    this.onExcegetList()
                    this.onRemoveupload()
                    // this.fileListUpload = [];
                    // window.open(res.data.file_url)
                }
            });
        },
        // onGain() {
        //     var param = {
        //         id: 5,
        //     };
        //     this.$service.get(this.$api.postExcelCost, param, (res) => {
        //         if (res.code == "200") {
        //             console.log(res)
        //             console.log("获取结果", res);
        //         }
        //     });
        // },
        //翻页
        DialogCurrentChange(val) {
            this.onExcegetList(val);
        },
        //上传文件移出
        onRemoveupload(file, fileList) {
            console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.file_id = "";
            // this.$message({
            //     message: "文件移出成功，请重新上传文件",
            //     type: "success",
            // });
            // this.onSubmit()
        },
        //文件上传成功
        onSuccessupload(res, file, fileList) {
            this.disabled = 0
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
                // this.onSubmit()
            }
        },
        //文件上传之前
        onChangeUpload(file, fileList) {
            this.disabled = 1 
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },
        //文件上传失败
        onError(){
            this.disabled = 0
        }
    },


    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.consumption{
    .consumptionTop{
    }
    .geshi{
        padding-bottom: 20px;
        a{
            color: #f00;
        }
    }
}
</style>
<style lang="scss">
</style>